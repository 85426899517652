<template>
  <section id="summary">
    <div class="container">
      <h1>{{contents.summary.title}}</h1>
      <div v-html="contents.summary.txt">

      </div>
      <a class="btn btn-primary" target="_blank" :href="contents.summary.href">{{contents.summary.btn}}</a>
    </div>
  </section>
  <div class="container">
    <section id="about">
      <h2>{{contents.about.title}}</h2>
      <div v-html="contents.about.txt"></div>
      <router-link class="btn btn-secondary" :to="{name : 'Page_251_'+userLanguage}">
        {{contents.about.moreBtn}}
      </router-link>
    </section>
    <section id="awards">
      <div class="row">
        <h2>{{contents.awards.title}}</h2>
        <div class="col-sm-4">
          <img src="@/assets/img/prize_1.jpg" alt="1st prize" />
          <h3>{{contents.awards.text1}}</h3>
        </div>
        <div class="col-sm-4">
          <img src="@/assets/img/prize_2.jpg" alt="1st prize" />
          <h3>{{contents.awards.text2}}</h3>
        </div>
        <div class="col-sm-4">
          <img src="@/assets/img/prize_3.jpg" alt="1st prize" />
          <h3>{{contents.awards.text3}}</h3>
        </div>
      </div>
    </section>
  </div>
  <section id="timing">
    <div class="container">
      <div class="row">
        <h2>{{contents.timing.title}}</h2>
        <div class="col-md-6" v-html="contents.timing.left">

        </div>
        <div class="col-md-6" v-html="contents.timing.right">

        </div>
      </div>
      <router-link class="btn btn-secondary" :to="{name : 'Page_251_'+userLanguage}">
        {{contents.timing.moreBtn}}
      </router-link>
    </div>
  </section>
  <div class="container text-center">
    <a class="apply-now" :href="contents.timing.applyHref" target="_blank" rel="noreferrer">
      <h3>{{contents.timing.applyNow}}</h3>
    </a>
  </div>
</template>

<script>

export default {
  name: 'MainPage',
  props:['globalData','pageId'],
  data(){

    return{

    }
  },
  computed:{
    userLanguage(){
      return this.globalData.init.language
    },
    contents(){
      let language = this.userLanguage
      let contents = this.globalData.contents[language][this.pageId]

      this.globalData.init.head.pageId = this.pageId
      this.globalData.init.head.title = contents.head.title
      this.globalData.init.head.keywords = contents.head.keywords
      this.globalData.init.head.description = contents.head.description

      return contents
    }
  }
}
</script>
<style scoped>
#summary{
  background: url('@/assets/img/summary.jpg') center center no-repeat;
  background-size: cover;
  color: #FFFFFF;
  margin-bottom: 5rem;
  height: 504px;
  position: relative;
}
#summary .container{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#summary h1{
  text-transform: uppercase;
  margin-bottom: 1rem;
}
#summary p{
  color: #FFFFFF;
}
#summary .btn{
  margin-right: 1rem;
}
#about{
  margin-bottom: 6.67rem;
}
#awards{
  margin-bottom: 3.5rem;
}
#awards img{
  max-width: 185px;
  max-height: 185px;
}
#awards h3{
  font-family: 'Karla', sans-serif;
  font-size: 16px;
  color: #333;
  max-width: 330px;
}
#timing{
  background-color: #f2f2f2;
  background-size: cover;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  margin-bottom: 5rem;
}
.apply-now{
  position: relative;
  display: inline-block;
  padding: 2.5rem 3rem 3rem 3rem;
  font-size: 1.25rem;
  line-height: 1.25rem;
  color: #284569;
  font-weight: bold;
}
.apply-now:before,.apply-now:after{
  width: 2.25rem;
  height: 7.4rem;
  position: absolute;
  content: '';
}
.apply-now:before{
  left: 0;
  top: 0;
  border-left: 6px solid #6ad1d5;
  border-top: 6px solid #6ad1d5;
  border-bottom: 6px solid #6ad1d5;
}

.apply-now:after{
  right: 0;
  top: 0;
  border-right: 6px solid #6ad1d5;
  border-top: 6px solid #6ad1d5;
  border-bottom: 6px solid #6ad1d5;
}
@media screen and (max-width: 576px) {
  #awards .col-sm-4{
    text-align: center;
  }
  #awards .col-sm-4 h3{
    max-width: none;
  }
}
</style>
